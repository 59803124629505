import { PaymentService } from "../services/paymentService";

export const getAge = (birthDate) =>
  Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);

export const handleSuccessFullPayment = (
  paymentDetails,
  paymentId,
  successCallback,
  failedCallback,
) => {
  PaymentService.paymentSuccessful(
    {
      gateway_payment_id: paymentDetails.razorpay_payment_id,
      gateway_signature: paymentDetails.razorpay_signature,
    },
    paymentId,
  )
    .then((res) => {
      if (res.status === 200) {
        alert("Payment successfully completed");
        successCallback();
      } else {
        alert("Payment failed please try again later");
        failedCallback();
      }
    })
    .catch(() => {
      alert("Payment failed please try again later");
      failedCallback();
    });
};

export const handleFailurePayment = (paymentDetails, paymentId) => {
  PaymentService.paymentFailed(
    {
      failure_description: paymentDetails.error.description,
    },
    paymentId,
  );
};

export const getLocalDateFormat = (createdOn) => {
  const localTimeFormat = new Date(createdOn);
  const uploadedDate = localTimeFormat.toLocaleDateString();
  return uploadedDate;
};

export const formateDate = (date) => {
  const cardDate = new Date(date);
  const d = cardDate.toDateString().split(" ");
  return `${d[2]} ${d[1]}, ${d[3]}`;
};

export const getFileType = (filename) => {
  return filename.split(".").pop();
};

export const capitalizeEachWordInString = (text) => {
  const capitalizedText = text.split(" "); // TODO: Fix this
  let fullName = text;
  try {
    fullName = capitalizedText
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(" ");
  } catch (error) {
    // console.log(error);
  }
  return fullName;
};

export const capitalizeText = (text) => {
  return text[0].toUpperCase() + text.substring(1);
};

export const formatCompetitionCardData = (data) => {
  return {
    contestId: data.contest_id,
    amount: data.amount,
    img: data.image_url,
    level: data.level,
    ages: `${data.min_age}-${data.max_age}`,
    date: data.submit_by,
    name: data.title,
    disabled: false,
    isEnrolled: data.enrolled,
    themeGuidelinesPDFLink: data.theme_guidelines_pdf,
    isLive: hasDatePassed(data.submit_by),
    resultDeclared: data.result_declared,
    certificateUploaded: data.certificate_uploaded,
    isFileUploaded: data.is_file_uploaded,
    isAFreeContest: data.is_contest_free,
    contestType: data?.contest_type,
  };
};

const hasDatePassed = (newDate) => {
  const today = new Date();
  const date = new Date(newDate);
  today.setHours(0, 0, 0, 0);
  if (date < today) {
    return false;
  }
  return true;
};

export const checkIfObjectIsEmpty = (obj) =>
  obj &&
  Object.keys(obj).length === 0 &&
  Object.getPrototypeOf(obj) === Object.prototype;

export const isEmailValid = (email) => {
  const re =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return re.test(email);
};

export const isPasswordValid = (password) => {
  const re = /((?=.*\d)(?=.*[a-z])\w.{4,}\w)/i;
  return re.test(password);
};

export const isPhoneNumValid = (phoneNum) => {
  return !isNaN(phoneNum) && phoneNum.length >= 8 && phoneNum.length <= 20;
};

export const isPinCodeValid = (pinCode) => {
  return !isNaN(pinCode) && pinCode.length === 6;
};

export const getUploadingFileType = (file) => {
  try {
    const fileType = file.type.split("/");
    const type = fileType[0];
    const extension = fileType[1];
    return { type, extension };
  } catch {
    return { type: "", extension: "" };
  }
};

export const getAllowedDateForDatePicker = (date = "") => {
  if (date) {
    try {
      return new Date(date).toISOString().split("T")[0];
    } catch (error) {
      console.error(error);
    }
  } else {
    return new Date().toISOString().split("T")[0];
  }
};

const allowedApplicationType = [
  "msword", //Microsoft word document application type
  "vnd.openxmlformats-officedocument.wordprocessingml.document", //Open word document application type
  "pdf", //pdf application type
  "x-cdf", // CD audio application type
  "x-mpegURL", // iPhone video format.
];

export const isFileValidToUpload = (
  MIMEType = "",
  isCheckingForImage = false,
) => {
  const mimeTypeObject = MIMEType.split("/");

  const fileTypeObject = {
    type: mimeTypeObject[0],
    subtype: mimeTypeObject[1],
  };

  if (isCheckingForImage) {
    return fileTypeObject.type === "image";
  } else if (["image", "video", "audio"].includes(fileTypeObject.type)) {
    return true;
  } else if (fileTypeObject.type === "application") {
    return allowedApplicationType.includes(fileTypeObject.subtype);
  } else {
    return false;
  }
};

export const yearDropdownOptions = (minYear = 2000, maxYear = 2100) =>
  Array.from(new Array(maxYear - minYear + 1), (v, i) => i + minYear);

export const shortMonthArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const addOrdinalSuffix = (number) => {
  if (number % 100 >= 11 && number % 100 <= 13) {
    return number + "th";
  }
  switch (number % 10) {
    case 1:
      return number + "st";
    case 2:
      return number + "nd";
    case 3:
      return number + "rd";
    default:
      return number + "th";
  }
};

export const getRating = (obtainedScore, maxScore, maxRating = 5) => {
  const starCount = Math.round((obtainedScore / maxScore) * maxRating);
  return starCount;
};

export const getFirstName = (fullName) => {
  if (!fullName) return "";
  return fullName.split(" ")[0];
};
