import axios from 'axios'
import axiosConfig from './axiosConfig'
import { Option, PaymentCode, PaymentFormDetails, PaymentMethod, PaymentType, SalesAdvisor } from 'models';

const baseURL = process.env.REACT_APP_BASE_URL

export const PaymentService = {
  initiatePayment: (paymentDetails: any) =>
    axios.post(`${baseURL}/payment/initiate`, paymentDetails, axiosConfig()),

  paymentSuccessful: (details: any, paymentId: string) =>
    axios.put(
      `${baseURL}/payment/success/${paymentId}`,
      details,
      axiosConfig()
    ),

  paymentFailed: (details: any, paymentId: string) =>
    axios.put(`${baseURL}/payment/fail/${paymentId}`, details, axiosConfig()),

  getPaymentDetails: (paymentId: string): Promise<PaymentFormDetails> => axios.get(`${baseURL}/payment/payment_code/${paymentId}`, axiosConfig()).then(res => {
    if (res.data.response_code === 80) {
      const paymentType = res.data.data.payment_code_type;
      const data = res.data.data;

      let paymentId = '';
      let paymentName = '';

      if (paymentType === PaymentType.SUBSCRIPTION) {
        paymentId = data.subscription_id;
        paymentName = data.name || "Subscription";
      } else if (paymentType === PaymentType.COMBO) {
        paymentId = data.combo_code;
        paymentName = data.combo_name || "Combo";
      }

      const basePaymentDetails = {
        agentId: data.agent_id || '',
        id: paymentId,
        name: paymentName,
        contestLevel: data.contest_level || '1',
        venuesOptions: (data.venue_details?.map((venue: string) => ({
          label: venue,
          value: venue
        })) || []) as Option[],
        isVenueRequired: data.is_venue_details_field_required,
        isReferralCodeAccepted: data.is_referral_code_field_required
      };

      if (paymentType === PaymentType.SUBSCRIPTION) {
        return {
          ...basePaymentDetails,
          paymentType: PaymentType.SUBSCRIPTION,
          durationInMonths: 12, // TODO: change this data.duration_in_months
          amount: data.price_in_INR,
          originalAmount: data.original_price_in_INR,
          benefits: data.detailed_benefits
        } as PaymentFormDetails;
      }

      if (paymentType === PaymentType.COMBO) {
        return {
          ...basePaymentDetails,
          paymentType: PaymentType.COMBO,
          discountPrice: data.discount,
          maximumContestants: data.combo_item_count
        } as PaymentFormDetails;
      }

      throw new Error("Invalid payment type");

    } else {
      throw new Error(res.data.developer_message);
    }
  }).catch(err => {
    throw new Error(err);
  }),
  initiatePublicPayment: (paymentId: string, payload: any) => axios.post(`${baseURL}/payment/payment_code/${paymentId}/initiate_payment`, payload, axiosConfig()),

  getPaymentMethods: () => axios.get(`${baseURL}/payment/payment_methods`, axiosConfig()).then(res => {
    if (res.data.response_code === 80) {
      return res.data.data.map((data: any) => ({
        id: data.id,
        name: data.name,
        description: data.description
      } as PaymentMethod))
    } else {
      throw new Error(res.data.developer_message);
    }
  }).catch(err => {
    throw new Error(err);
  }),

  getPaymentCodes: () => axios.get(`${baseURL}/payment/payment_code?payment_code_type=non_razorpay`, axiosConfig()).then(res => {
    if (res.data.response_code === 80) {
      return res.data.data.map((data: any) => ({
        paymentCode: data.payment_code,
        paymentCodeType: data.payment_code_type,
        contestCount: data.contest_count,
        contestLevel: data.contest_level
      } as PaymentCode))
    } else {
      throw new Error(res.data.developer_message);
    }
  }),

  getSalesAdvisors: () => axios.get(`${baseURL}/sales/sales_person`, axiosConfig()).then(res => {
    if (res.data.response_code === 80) {
      return res.data.data.map((data: any) => ({
        email: data.email,
        mobile: data.mobile,
        name: data.name,
        salesPersonCode: data.sales_person_code,
        salesPersonId: data.sales_person_id
      }) as SalesAdvisor)
    } else {
      throw new Error(res.data.developer_message);
    }
  }),

  nonRazorpayPaymentVerificationFormSubmit: (payload: any) => axios.post(`${baseURL}/payment/non_razorpay_payment`, payload, axiosConfig()).then(res => {
    if (res.data.response_code === 80) {
      return res.data.data;
    } else {
      throw new Error(res.data.developer_message);
    }
  })

}
